import { User } from '@/api/auth/types';

export const FAKE_TWK_USER: User = {
  access_token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjBmNjVjNmRkNzgxYzE1YmYxMGU5MzQ3YTAyN2Q2YWY3ODgzOWVlNzk5NjNlZmVmODI5NzkxMTkyYjA3MzRlMjM0NjhhZmVmY2U5ZGM1Y2NjIn0.eyJhdWQiOiI2NGIzOGRkNmU2NzJkZDk3NTEwNmEyNTIiLCJqdGkiOiIwZjY1YzZkZDc4MWMxNWJmMTBlOTM0N2EwMjdkNmFmNzg4MzllZTc5OTYzZWZlZjgyOTc5MTE5MmIwNzM0ZTIzNDY4YWZlZmNlOWRjNWNjYyIsImlhdCI6MTY5MDM5NDkwOCwibmJmIjoxNjkwMzk0OTA4LCJleHAiOjE3MjIwMTczMDgsInN1YiI6IjY0YzEyN2EyNjU0ZTBlZmRlMTBjY2ZjNyIsInNjb3BlcyI6W119.kPt_c8z7SEK13WhOG-qlhKjubKCODsSzYCh6d-Q5TfZtw2IlbXuv2HExuJAXNbF2gYhB1n3GZQC_VU2SoCqqtcWfWLA9IQRrPPqveGl_NYNcLwAk2TYa5p61oIPVbaRuik9AMIuHwpQvxFgVTcRlv_X8SZ1HXgX488QbtHLlN_8BDsl7HydDoVfrXDSjXXpR0ejmht5KSzD0epMWEJp88fdSFEgnEgmXJAuJD4JPPYpcgB8HT7vOllUcA7a4xy8RjDqLuOw9qTvUEQj5GMMuK5pI_UFKuIijpNppFfQZPHW2UUgok0U-Qa3jJJCUX3JzJD6Fgt4Xd_tx72GHqmEVQfdvp63dQjiIuIWv_nONMKVdxdi0gjatDEX2rc8bznzj38dRWkZ4y3qHEMe7S86ELcF9YMegDodtvODl-200Us3KFL2o2jX67sZtoSA0jPef2Y25KujTs1RpeJiPXWoR37xXXenWChUxYg3nm7o2ipHlnEB-8qsgV4kcoptlnpyKgT3zFmokvCwpdWxV4rG4HXUImGQK_06wlaYrMbmmIlYCRyKsRcwl6TzhvGH11cr_zSdqhZHq7lkpXAaErgAhkNrw2v6hU8oVs5r_rkTLq5DtNzPABLitcuBB2_R02XL90syWgc9KYPg0JneWTq-9fsmMZkeqm2bMjUDU2TGMgvs',
  _id: '64c127a2654e0efde10ccfc7',
  name: 'Alawwal Guest',
  email: 'abdulraoof+itti@uxbert.com',
  phone: '+966507487620',
  city: 'Riyadh',
  sms_verified: false,
  country: 'SA',
  nationality: 'YE',
  address: '',
  dob: '06/07/2005',
  gender: 'male',
  season_order_details: [
    {
      season_id: '64a439b506ba280d0e5b93e4',
      total_ordered_tickets: 0,
    },
    {
      season_id: '64ae8f4e3aa947227041f323',
      total_ordered_tickets: 0,
    },
  ],
  first_name: 'Abdulraoof',
  last_name: 'Aldabil',
  cell_country_code: 'SA',
  cell_number: '+966507487620',
  new_user: false,
  is_tawakkalna: true,
  early_bird_eligible: true,
  favorite_team: '',
  event_order_details: [],
  referral_count: 0,
  referral_share_key: '',
  early_bird: [],
  is_guest: false,
};
