import Copyright from './Copyright';

const MiniFooter = () => {
  return (
    <footer className='bg-secondary 2lg:block hidden p-4'>
      <div className='container mx-auto flex flex-wrap items-center justify-center gap-4'>
        <Copyright />
      </div>
    </footer>
  );
};

export default MiniFooter;
