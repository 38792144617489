import { User } from '@/api/auth/types';
import { ErrorDialogProps, TrackContext } from './types';

const isDatadog = process.env.NEXT_PUBLIC_ERROR_TRACKING_SERVICE === 'datadog';

export const captureException = async (exception: Error, context?: TrackContext) => {
  if (isDatadog) {
    const datadogModule = await import('./datadog');
    return datadogModule.datadogCaptureException(exception, context);
  } else {
    const sentryModule = await import('./sentry');
    return sentryModule.sentryCaptureException(exception, context);
  }
};

export const captureMessage = async (message: string, context?: TrackContext) => {
  if (isDatadog) {
    const datadogModule = await import('./datadog');
    return datadogModule.datadogCaptureMessage(message, context);
  } else {
    const sentryModule = await import('./sentry');
    return sentryModule.sentryCaptureMessage(message, context);
  }
};

export const setUser = async (user: Partial<User>) => {
  if (isDatadog) {
    const datadogModule = await import('./datadog');
    return datadogModule.datadogSetUser(user);
  } else {
    const sentryModule = await import('./sentry');
    return sentryModule.sentrySetUser(user);
  }
};

export const showReportDialog = async (options: ErrorDialogProps) => {
  if (isDatadog) {
    // TODO: implement own dialog?
  } else {
    const sentryModule = await import('./sentry');
    return sentryModule.sentryShowReportDialog(options);
  }
};

export const lastId = async () => {
  if (isDatadog) {
    const datadogModule = await import('./datadog');
    datadogModule.datadogSessionId();
  } else {
    const sentryModule = await import('./sentry');
    return sentryModule.sentryLastEventId();
  }
};
