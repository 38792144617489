import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import { SOCIAL_MEDIA } from '@/src/constants/social';
import { HEADER_NAV } from '../nav';
import UserProfileButton from '../UserProfileButton';
import Sponsors from '../../footer/Sponsors';
import MenuItem from './MenuItem';

const MobileHeader = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const { t } = useTranslation();
  const { locale, pathname } = useRouter();

  return (
    <>
      {isOpen && <style>{'body{overflow:hidden}'}</style>}
      <motion.nav
        className='absolute top-0 z-50 h-full w-full overflow-hidden text-xl font-semibold lg:hidden'
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { width: '100%' },
          closed: { width: 0, transition: { delay: 0.5 } },
        }}
      >
        <motion.div
          className='bg-secondary fixed left-0 top-0 z-20 h-full w-0'
          variants={{
            open: {
              width: '100vw',
            },
            closed: {
              width: 0,
              transition: {
                delay: 0.5,
                type: 'spring',
                stiffness: 400,
                damping: 40,
              },
            },
          }}
        />
        <motion.ul
          className='mini-scrollbar container absolute left-1/2 z-20 max-h-screen w-screen max-w-sm -translate-x-1/2 space-y-4 overflow-y-auto px-6 py-24'
          variants={{
            open: {
              transition: { staggerChildren: 0.07, delayChildren: 0.2 },
            },
            closed: {
              transition: { staggerChildren: 0.05, staggerDirection: -1 },
            },
          }}
        >
          <MenuItem>
            <UserProfileButton onClick={() => toggleOpen()} />
          </MenuItem>
          {HEADER_NAV.map((link, i) => {
            const path = link[`href_${locale}` as 'href_en'] || link.href_en;
            return (
              <MenuItem key={i}>
                <Link
                  href={path}
                  className={`hover:text-primary focus:text-primary flex items-center gap-2 p-1 uppercase transition ${pathname === path ? 'text-primary' : ''}`}
                  data-title={link.title}
                  data-location='header'
                  onClick={() => toggleOpen()}
                >
                  {t(`common:nav.${link.title}`)}
                </Link>
              </MenuItem>
            );
          })}

          <MenuItem>
            <hr className='border-gray-700' />
          </MenuItem>

          <MenuItem>
            <figure className='relative mx-auto mt-4 h-20 w-20'>
              <Image
                className=''
                src='/icons/logo.png'
                fill
                sizes='163px'
                alt={t('common:seo.title')}
              />
            </figure>
            <div className='mt-6 [&>div>ul>li>img]:max-w-[70px]'>
              <Sponsors />
            </div>
            <ul className='my-8 flex items-center justify-center gap-4'>
              {SOCIAL_MEDIA.map((link) => (
                <li
                  key={link.en}
                  className='hover:bg-primary/10 inline-flex rounded-lg border border-white transition-colors'
                >
                  <a
                    href={link[locale as 'en'] || link.en}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='social_media_follow p-2'
                    data-location='footer'
                    data-category={link.name}
                  >
                    <Image width={17} height={17} src={link.icon} alt={link.name} className='w-6' />
                  </a>
                </li>
              ))}
            </ul>
          </MenuItem>
        </motion.ul>
      </motion.nav>

      <ul className='container relative z-50 grid list-none grid-cols-4 items-center px-4 lg:hidden'>
        <li className='flex h-14 items-center justify-start'>
          <button
            className='relative flex h-12 w-12 items-center justify-center p-2'
            onClick={() => toggleOpen()}
          >
            <AnimatePresence mode='wait'>
              {isOpen ? (
                <motion.div
                  key='open'
                  className='origin-center font-mono text-2xl font-bold'
                  initial={{ scale: 0 }}
                  animate={{ scale: [0, 1.2, 1] }}
                  exit={{ scale: [1, 1.2, 0] }}
                >
                  x
                </motion.div>
              ) : (
                <motion.div
                  key='close'
                  className='relative h-8 w-8'
                  initial={{ scale: 0 }}
                  animate={{ scale: [0, 1.2, 1] }}
                  exit={{ scale: [1, 1.2, 0] }}
                >
                  <Image
                    src='/icons/common/burger.svg'
                    fill
                    sizes='28px'
                    alt={t('common:seo.title')}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        </li>

        <li className='col-span-2 flex justify-center py-4'>
          <Link
            href='/'
            className='logo-image relative block h-10 w-10 shrink-0 hover:opacity-80'
            data-location='header'
          >
            <Image src='/icons/logo.png' fill sizes='163px' alt={t('common:seo.title')} />
          </Link>
        </li>

        <li className='flex justify-end py-4 [&>a]:p-1'>
          <LanguageSwitch />
        </li>
      </ul>
    </>
  );
};

export default MobileHeader;
