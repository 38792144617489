import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import MainButton from '@/components/buttons/MainButton';

type Props = {
  onClick?: () => void;
};

const UserProfileButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <MainButton
      as={Link}
      href='/profile/bookings'
      shape='outlined'
      data-testid='login-profile'
      data-location='header'
      className='w-full gap-1'
      role='menuitem'
      onClick={onClick}
    >
      <p>{t('common:nav.my_bookings')}</p>
    </MainButton>
  );
};

export default UserProfileButton;
