import DesktopHeader from './Desktop';
import MobileHeader from './mobile';

type Props = {
  settings: LayoutProps['header'];
};

const Header = ({ settings }: Props) => {
  if (settings?.type === 'booking') return null;

  return (
    <header className='bg-secondary-light z-50 w-full'>
      <DesktopHeader settings={settings} />
      <MobileHeader />
    </header>
  );
};

Header.defaultProps = {
  settings: {
    type: 'main',
  },
};

export default Header;
