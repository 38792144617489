import { useCallback, useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';
import { createCookie, readCookie } from '@/util/cookies';
import { useGetUser } from '@/api/auth/getUser';
import { User } from '@/api/auth/types';
import usePixels from '@/src/hooks/usePixels';
import { AuthModalConfig } from './types';
import authReducer, { initialAuthState } from './reducer';
import AuthContext from './context';
import { FAKE_TWK_USER } from './tawakkalna';

type Props = { children: React.ReactNode };

const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const { locale, query } = useRouter();
  const { push } = useRouter();
  const { mutateAsync: getUser } = useGetUser();
  const { setUserInDataLayer } = usePixels();
  // TEMP check for token_no
  const canTawakkalnaLogin = !!query.token_no && process.env.NEXT_PUBLIC_ENV !== 'production';

  const initUser = useCallback(async () => {
    try {
      if (canTawakkalnaLogin) {
        fakeTawakkalnaUser();
      } else {
        const token = readCookie('token');
        const canFetch = token && !state.user;

        if (canFetch) {
          const user = await getUser({ locale });
          login({ ...user, access_token: token });
        }
      }
    } catch (error) {
      dispatch({ type: 'LOGOUT' });
      // Silent
    } finally {
      dispatch({ type: 'POST_AUTH' });
    }

    // Once what-so-ever
    // eslint-disable-next-line
  }, [canTawakkalnaLogin]);

  useEffect(() => {
    initUser();
  }, [initUser]);

  const fakeTawakkalnaUser = useCallback(async () => {
    if (canTawakkalnaLogin) {
      // const favTeam = readCookie('favorite_team') || '';
      login({ ...FAKE_TWK_USER, favorite_team: '' });
      createCookie('token_no', query.token_no as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.token_no]);

  useEffect(() => {
    fakeTawakkalnaUser();
  }, [fakeTawakkalnaUser]);

  const login = useCallback(
    (user: User) => {
      dispatch({ type: 'LOGIN', payload: { user } });
      setUserInDataLayer(user);
    },
    [setUserInDataLayer]
  );

  const logout = (redirectTo?: string) => {
    dispatch({ type: 'LOGOUT' });

    if (redirectTo) {
      const href = redirectTo.startsWith('/') ? redirectTo : `/${redirectTo}`;
      push(`/${href}`);
    }
  };

  const updateUser = (updatedFields: Partial<User>) => {
    const merged = Object.assign((state.user || {}) as User, updatedFields);
    dispatch({ type: 'UPDATE_USER', payload: { user: merged } });
  };

  const toggleModal = useCallback((config?: AuthModalConfig) => {
    dispatch({ type: 'TOGGLE_MODAL', payload: config });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        updateUser,
        toggleModal,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
