import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

type Props = {
  width?: number;
  height?: number;
  hoverable?: boolean;
};
const WBKLogo = ({ width = 75, height = 43, hoverable = true }: Props) => {
  const { locale } = useRouter();
  return (
    <Link href={`https://webook.com/${locale}`} target='_blank' rel='noreferrer'>
      <Image
        className={`w-auto transition ${hoverable ? 'hover:scale-110' : ''}`}
        width={width}
        height={height}
        src='/icons/wbk-logo.svg'
        alt='webook'
      />
    </Link>
  );
};

export default WBKLogo;
