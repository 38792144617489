import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from '../axios';
import { User } from './types';

type Props = { locale?: string };

const getUser = async ({ locale }: Props): Promise<User> => {
  try {
    const { data } = await axiosInstance.get(`/user/profile?lang=${locale}`);

    if (data.status?.toLowerCase() !== 'success') {
      const msg = Object.values(data?.error || {})[0] || data?.original?.error?.description;
      throw new Error(msg);
    }

    return data.data;
  } catch (error) {
    // This suposed to be silent
    throw error;
  }
};

export const useGetUser = () => {
  return useMutation({
    mutationFn: getUser,
  });
};
