import { createCookie, eraseCookie } from '@/util/cookies';
import { setUser } from '@/src/errors/logger';
import { AuthAction, AuthReducer } from './types';

export const initialAuthState: AuthReducer = {
  authLoading: true,
  authenticated: false,
  modal: false,
  user: undefined,
  initialMode: 'email',
  showBackButton: false,
  dismissible: true,
  isEarlyBird: false, // new Date(2023, 6, 23).getTime() - Date.now() > 0,
  toggleModal: () => undefined,
  updateUser: () => undefined,
  logout: () => undefined,
  login: () => undefined,
};

const authReducer = (state: AuthReducer = initialAuthState, action: AuthAction): AuthReducer => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        modal: !state.modal,
        // Registered config explicitly to prevent left over previous config/callbacks.
        onCloseModal: action.payload?.onCloseModal,
        onLoginSuccess: action.payload?.onLoginSuccess,
        onMobileRegisteredSuccess: action.payload?.onMobileRegisteredSuccess,
        onOtpFail: action.payload?.onOtpFail,
        onAuthRedirectTo: action.payload?.onAuthRedirectTo,
        dismissible:
          typeof action.payload?.dismissible !== 'undefined' ? action.payload.dismissible : true,
        // Keep old config when closing, to prevent title flickering
        title: state.modal ? state.title : action.payload?.title,
        showBackButton: state.modal ? state.showBackButton : action.payload?.showBackButton,
        initialMode: state.modal ? state.initialMode : action.payload?.initialMode || 'email',
      };
    case 'LOGIN':
      const expiry = Number(process.env.NEXT_PUBLIC_TOKEN_COOKIES_AGE || 1);
      createCookie('token', action.payload?.user?.access_token || '', expiry);

      /** Save user in logger */
      setUser({
        _id: action.payload?.user?.cell_number,
        email: action.payload?.user?.email,
        name: action.payload?.user?.name,
      });
      return {
        ...state,
        ...action.payload,
        authenticated: true,
      };
    case 'LOGOUT':
      eraseCookie('token');
      return {
        ...state,
        ...action.payload,
        authenticated: false,
        user: undefined,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.payload?.user || state.user,
      };
    case 'POST_AUTH':
      return {
        ...state,
        authLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
