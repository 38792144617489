import useDeviceDetect from '@/hooks/useDevice';

type Props = {
  children: React.ReactElement;
};

const HideOnWebview: React.FC<Props> = ({ children }) => {
  const { isWebView } = useDeviceDetect();

  if (isWebView) return null;

  return children;
};

export default HideOnWebview;
