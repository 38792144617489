import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { SOCIAL_MEDIA } from '@/src/constants/social';
import AppsDownload from '@/components/common/AppsDownload';
import Copyright from './Copyright';
import { FOOTER_NAV } from './nav';
import Sponsors from './Sponsors';

const MainFooter = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <footer className='bg-secondary-light pb-6'>
      <div className='container space-y-8 py-6'>
        <figure className='relative mx-auto h-16 w-16'>
          <Image
            className=''
            src='/icons/logo.png'
            fill
            sizes='163px'
            alt={t('common:seo.title')}
          />
        </figure>

        <Sponsors />
      </div>

      <div className='container flex items-center justify-between border-t border-white/20 py-8'>
        <nav className='mx-auto max-w-2xl rtl:max-w-2xl'>
          <ul className='flex flex-wrap justify-center gap-4 md:justify-normal'>
            {FOOTER_NAV.map((item) => (
              <li key={item.title}>
                <a
                  href={item[`href_${locale || 'en'}` as 'href_en'] || item.href_en}
                  target='_blank'
                  rel='noreferrer noopener'
                  className='footer_nav hover:text-primary p-2 uppercase underline-offset-4 transition hover:underline'
                  data-location='footer'
                  data-category={item.title}
                >
                  {t(`common:nav.${item.title}`)}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className='container flex flex-col items-center justify-between gap-5 border-t border-white/20 py-8 md:flex-row'>
        <ul className='flex items-center justify-center gap-4'>
          {SOCIAL_MEDIA.map((link) => (
            <li
              key={link.en}
              className='hover:bg-primary/10 inline-flex rounded-lg border border-white transition-colors'
            >
              <a
                href={link[locale as 'en'] || link.en}
                target='_blank'
                rel='noreferrer noopener'
                className='social_media_follow p-2'
                data-location='footer'
                data-category={link.name}
              >
                <Image width={17} height={17} src={link.icon} alt={link.name} className='w-6' />
              </a>
            </li>
          ))}
        </ul>
        <div className='flex flex-col items-center gap-2 lg:flex-row'>
          <p className='text-center text-sm'>{t('download_app')}</p>
          <AppsDownload />
        </div>
      </div>

      <div className='container flex items-center justify-end gap-4 border-t border-white/20 py-8'>
        <Copyright />
      </div>
    </footer>
  );
};

export default MainFooter;
