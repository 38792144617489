import { SocialProfileJsonLd } from 'next-seo';

export default function SocialJsonLd() {
  return (
    <SocialProfileJsonLd
      type='Organization'
      name='Hala Yalla'
      url='https://halayalla.com'
      sameAs={[
        'https://twitter.com/halayalla',
        'https://www.instagram.com/halayalla',
        'https://www.facebook.com/halayalla',
      ]}
    />
  );
}
