import axios, { type AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';
import { readCookie } from '@/util/cookies';

/**
 * Tickets Instance
 */
export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_TICKETS_API_BASE,
  headers: { token: process.env.NEXT_PUBLIC_TICKETS_API_TOKEN },
  timeout: 30000,
  // withCredentials: true,
});

axiosInstance.interceptors.request.use(axiosRequestHandler);
axiosInstance.interceptors.response.use(axiosResponseFulfill, axiosResponseError);

export function axiosRequestHandler(config: unknown) {
  const configs = config as InternalAxiosRequestConfig;
  if (!configs?.headers) return configs;

  if (typeof window !== 'undefined') {
    const token = readCookie('token');
    if (token && !configs?.headers?.Authorization) {
      configs.headers.Authorization = 'Bearer ' + token;
    }
  }

  return configs;
}

export function axiosResponseFulfill(response: AxiosResponse) {
  return Promise.resolve(response);
}

export function axiosResponseError(error: AxiosError) {
  if (error.message === 'Network Error') {
    error.message =
      "You've exceeded the maximum number of attempts for this action. Please try again in a while.";
  }
  return Promise.reject(error);
}
