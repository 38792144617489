import { loadScriptAsync } from '@/util/loadScriptAsync';
import { EventOrder } from '@/api/booking/types';
import useAuth from '@/src/context/auth/useAuth';
import { User } from '@/api/auth/types';
import { Event, EventListing } from '@/api/events/types';
import { BookingData } from '@/src/context/booking/context';
import { Organization } from '@/api/organization/types';

const IS_PROD = process.env.NEXT_PUBLIC_ENV === 'production';

const pushToDataLayer = (obj: Record<string, unknown>) => {
  if (typeof window !== 'undefined') {
    const dataLayer = window?.dataLayer;
    if (dataLayer) {
      dataLayer.push(obj);
      if (!IS_PROD) {
        console.log('Pushed to dataLayer:', obj);
      }
    }
  }
};

const usePixels = () => {
  const { user } = useAuth();

  const initGTM = () => {
    if (!IS_PROD) return;
    const GTM_IDS = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER?.split(',')?.filter((id) => id);
    if (!GTM_IDS) return;
    // Init GTM
    for (const id of GTM_IDS) {
      if (!id) continue;
      // Header GTM
      loadScriptAsync({
        id,
        target: 'head',
        snippet: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${id}');`,
      });
      // Body Iframe
      loadScriptAsync({
        id: `${id}_noscript`,
        type: 'noscript',
        snippet: `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      });
    }
  };

  const initGtag = () => {
    if (!IS_PROD) return;
    const GTAG_IDS = process.env.NEXT_PUBLIC_GOOGLE_GTAG?.split(',')?.filter((id) => id);
    if (!GTAG_IDS) return;
    for (const ID of GTAG_IDS) {
      loadScriptAsync({
        id: `${ID}_gtag`,
        url: `https://www.googletagmanager.com/gtag/js?id=${ID}`,
      });
      loadScriptAsync({
        id: `${ID}_gtag_script`,
        snippet: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${ID}')`,
      });
    }
  };

  const loginEvent = (method: string, user: User) => {
    pushToDataLayer({
      event: 'login',
      method,
      user: { ...user, access_token: '' },
    });
  };

  const signupEvent = (method: string, user: User) => {
    pushToDataLayer({
      event: 'signup',
      method,
      user: { ...user, access_token: '' },
    });
  };

  const setUserInDataLayer = (user: User) => {
    pushToDataLayer({
      user: { ...user, access_token: '' },
    });
  };

  const viewItemEvent = (event: Event) => {
    // Clear ecommerce object
    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
      event: 'view_item',
      ecommerce: {
        currency: event.currency,
        value: event.event_start_price,
        items: event.event_tickets.map((item) => ({
          item_id: item._id,
          item_name: item.title,
        })),
      },
    });
  };

  const viewItemListEvent = (organization: Organization, events: EventListing[]) => {
    // Clear ecommerce object
    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: organization._id,
        item_list_name: organization.name,
        items: events.map((event) => ({
          item_id: event._id,
          item_name: event.title,
        })),
      },
    });
  };

  const generateLeadEvent = (event: Pick<Event, 'currency' | 'event_start_price'>) => {
    pushToDataLayer({
      event: 'generate_lead',
      currency: event.currency,
      value: event.event_start_price,
    });
  };

  const cartEvent = (item: Event['event_tickets'][0], quantity: number, type: 'add' | 'remove') => {
    // Clear ecommerce object
    pushToDataLayer({ ecommerce: null });
    const event = type === 'add' ? 'add_to_cart' : 'remove_from_cart';
    pushToDataLayer({
      event,
      ecommerce: {
        currency: item.currency,
        value: +item.price,
        items: [
          {
            item_id: item._id,
            item_name: item.title,
            quantity,
            price: item.price,
            discount: +item.original_price - +item.price,
            item_category: item.title,
          },
        ],
        /** Meta/Snapchat helpers */
        number_items: quantity,
        item_ids: [item._id],
      },
    });
  };

  const beginCheckout = (items: BookingData['plainTickets'], coupon: BookingData['coupon']) => {
    // Clear ecommerce object
    pushToDataLayer({ ecommerce: null });
    const totalValue = items.reduce((acc, item) => acc + +item.price * item.qty, 0);
    pushToDataLayer({
      event: 'begin_checkout',
      ecommerce: {
        currency: items[0]?.currency,
        value: totalValue,
        coupon: coupon.promo_code || '',
        items: items.map((item) => {
          const discount = +item.original_price - +item.price;
          const hasCoupon = coupon.ticket_ids.includes(item.ticket_id || item._id);
          return {
            item_id: item.ticket_id || item._id,
            item_name: item.title,
            quantity: item.qty,
            price: +item.price,
            discount: hasCoupon ? (+item.price * coupon.discount) / 100 : discount,
            item_category: item.title,
          };
        }),
        /** Meta/Snapchat helpers */
        number_items: items.reduce((acc, item) => acc + item.qty, 0),
        item_ids: items.map((item) => item.ticket_id || item._id),
      },
    });
  };

  const purchaseEvent = async (payment: EventOrder) => {
    const summaries = payment.summary || payment.summaries || [];

    // Retrieve necessary user data from local storage
    const userDataString = localStorage.getItem('user_data');
    const userData = userDataString ? JSON.parse(userDataString) : {};

    // Retrieve necessary data from local storage
    const checkoutDataString = localStorage.getItem('checkoutData');
    const checkoutData = checkoutDataString ? JSON.parse(checkoutDataString) : null;

    pushToDataLayer({ ecommerce: null }); // clear ecommerce object

    // setting up the items
    const items = summaries.flatMap((summary) =>
      summary.tickets_details.map((item) => ({
        item_id: item.event_ticket_id,
        item_name: summary.event?.title || '',
        item_category: checkoutData?.item_category,
        quantity: 1,
        price: +item.ticket_price,
        ticket_class: item.event_ticket_title,
        item_category2: summary.event?.city || '',
        zone: checkoutData?.zone,
        google_business_vertical: 'retail',
        id: item.event_ticket_id,
      }))
    );

    pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        transaction_id: payment.order_id,
        currency: payment.total.currency,
        shipping: 0,
        tax: payment.total.vat_total,
        value: payment.total.grand_total,
        discount: payment.total.discount_amount,
        payment_type: payment.booked_type,
        coupon: payment.promo_code || '',
        items: items,
        /** Meta/Snapchat helpers */
        number_items: items.length,
        item_ids: items.map((item) => item.item_id),
      },
      user_data: {
        data: [userData],
      },
    });

    localStorage.removeItem('checkoutData');
  };

  const shareEvent = (method: string, id: string, contentType = '') => {
    pushToDataLayer({
      event: 'share',
      method,
      id,
      content_type: contentType,
      user,
    });
  };

  return {
    initGTM,
    initGtag,
    loginEvent,
    signupEvent,
    setUserInDataLayer,
    viewItemEvent,
    viewItemListEvent,
    generateLeadEvent,
    cartEvent,
    beginCheckout,
    purchaseEvent,
    shareEvent,
  };
};

export default usePixels;
